<template>
  <div class="container">
    <div class="head">
      <div class="headModel bg1 flexbox flex-lr flex-align-center flex-justify-between">
        <div class="headModelCon flex_1">
          <div class="headModelCon_num">¥{{index.withdrawFinance}}</div>
          <div class="headModelCon_title ">
            <div class="flexbox flex-lr flex-align-center">
              <div class="headModelCon_title_txt">可提现收益</div>
              <img class="question" src="/img/index/question_w.png" alt="" @click="showTips(1)">
            </div>
            <div class="allProfit">总收益：¥{{index.totalFinance}}</div>
          </div>
          <!-- 如果开启提现就显示提现按钮，反之不显示，1 开启 2关闭 -->
          <div v-if="index.fundState===1" class="headModelCon_btn flexbox flex-lr flex-align-center flex-justify-center"
            @click="toPage('WithdrawalRecord')">
            <div class="headModelCon_btn_txt">提现</div>
            <img class="more" src="/img/index/more_w.png" alt="">
          </div>
        </div>
        <img class="headIcon" src="/img/index/wallet.png" alt="">
      </div>
      <div class="headModel bg2 flexbox flex-lr flex-align-center flex-justify-between">
        <div class="headModelCon flex_1">
          <div class="headModelCon_num">{{totalShop}}</div>
          <div class="headModelCon_title">
            <div class="flexbox flex-lr flex-align-center">
              <div class="headModelCon_title_txt">已拥有商户</div>
              <img class="question" src="/img/index/question_w.png" alt="" @click="showTips(2)">
            </div>
          </div>
          <div class="headModelCon_btn flexbox flex-lr flex-align-center flex-justify-center" @click="toPage('Reviewed')">
            <div class="headModelCon_btn_txt">详情</div>
            <img class="more" src="/img/index/more_w.png" alt="">
          </div>
        </div>
        <img class="headIcon" src="/img/index/existBusiness.png" alt="">
      </div>
      <div class="headModel bg3 flexbox flex-lr flex-align-center flex-justify-between">
        <div class="headModelCon flex_1">
          <div class="headModelCon_num">{{index.unPassMember}}</div>
          <div class="headModelCon_title">
            <div class="flexbox flex-lr flex-align-center">
              <div class="headModelCon_title_txt">待提交核单商户</div>
              <img class="question" src="/img/index/question_w.png" alt="" @click="showTips(3)">
            </div>
          </div>
          <div class="goOnDeveloping">开发中</div>
          <!--
          <div class="headModelCon_btn flexbox flex-lr flex-align-center flex-justify-center" @click="toPage('WaitAudit')">
            <div class="headModelCon_btn_txt">详情</div>
            <img class="more" src="/img/index/more_w.png" alt="">
          </div>
          -->
        </div>
        <img class="headIcon" src="/img/index/examineBusiness.png" alt="">
      </div>
    </div>
    <div class="middle">
      <div class="firstTitle mb20 flexbox flex-lr flex-align-center">订单数据</div>
      <div class="middleContent">
        <div class="middleConModel flexbox flex-tb flex-align-center flex-justify-center">
          <div class="middleConModel_con">{{moneyList.mchMoneyMonthly}}</div>
          <div class="flexbox flex-lr flex-align-center flex-justify-center">
            <div class="middleConModel_title">本月订单金额</div>
            <img class="question" src="/img/index/question_b.png" alt="" @click="showTips(4)">
          </div>
        </div>
        <div class="middleConModel flexbox flex-tb flex-align-center flex-justify-center">
          <div class="middleConModel_con">{{moneyList.mchCommissionMonthly}}</div>
          <div class="flexbox flex-lr flex-align-center flex-justify-center">
            <div class="middleConModel_title">本月参与抽佣金额</div>
            <img class="question" src="/img/index/question_b.png" alt="" @click="showTips(5)">
          </div>
        </div>
        <div class="middleConModel border_right flexbox flex-tb flex-align-center flex-justify-center">
          <div class="middleConModel_con">{{moneyList.mchProfitMonthly}}</div>
          <div class="flexbox flex-lr flex-align-center flex-justify-center">
            <div class="middleConModel_title">本月分润金额</div>
            <img class="question" src="/img/index/question_b.png" alt="" @click="showTips(6)">
          </div>
        </div>
        <div class="middleConModel flexbox flex-tb flex-align-center flex-justify-center">
          <div class="middleConModel_con">{{effectiveShopByMonth}}</div>
          <div class="flexbox flex-lr flex-align-center flex-justify-center">
            <div class="middleConModel_title">本月新增有效商户</div>
            <img class="question" src="/img/index/question_b.png" alt="" @click="showTips(7)">
          </div>
        </div>
        <div class="middleConModel flexbox flex-tb flex-align-center flex-justify-center">
          <div class="middleConModel_con">{{expireShopByDay}}</div>
          <div class="flexbox flex-lr flex-align-center flex-justify-center">
            <div class="middleConModel_title">15天内到期续费商户</div>
            <img class="question" src="/img/index/question_b.png" alt="" @click="showTips(8)">
          </div>
        </div>
      </div>
    </div>
    <div class="bottom flexbox flex-lr flex-align-stretch flex-justify-center">
      <div class="content-bottom content">
        <div class="content-title flexbox flex-lr flex-align-center flex-justify-center">
          <div class="firstTitle flexbox flex-lr flex-align-center flex_1">数据统计</div>
          <div>
            <el-button :type="activeTime === 7 ? 'primary' : ''" size="mini" @click="chooseTime(7)">近7天</el-button>
            <el-button :type="activeTime === 30 ? 'primary' : ''" size="mini" @click="chooseTime(30)">近30天</el-button>
          </div>
        </div>
        <div id="chart_content" style="flex:1;"></div>
      </div>
      <div class="bottomRight">
        <div class="bottomRightHead flexbox flex-lr flex-align-center flex-justify-center">
          <div class="firstTitle flexbox flex-lr flex-align-center flex_1">系统公告</div>
          <div class="goDetail" @click="toPage('Notice')">全部 ></div>
        </div>
        <div class="bottomRightContentDiv">
          <div class="bottomRightContent">
            <template v-if="noticeList.length>0">
              <div class="contentLi" v-for="(item, index) in noticeList" :key="index">
                <div class="contentLiTimeAndStatus flexbox flex-lr flex-align-center flex-justify-between">
                  <div class="contentLiTime">{{item.createTime}}</div>
                  <div class="contentLiStatus">{{item.readState===1?'已读':'未读'}}</div>
                </div>
                <div class="contentLiTxt" :class="item.readState===0?'contentLiTxtActive':''" @click="select(index)">
                  <div class="contentLiTitle">{{item.title}}</div>
                  <div class="contentLiTxtCon">{{item.content}}</div>
                </div>
              </div>
            </template>
            <div class="noneData center" v-if="noticeList.length===0">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <Dialog ref="indexDialog"></Dialog>
  </div>
</template>

<script>
import highCharts from 'highcharts';
import {
  $sg_queryIndexDetail,
  $sg_queryProfitDetail,
  $sg_queryAgentMaxFund,
  $sg_queryNoticeDetail,
  $sg_queryShopDetail,
  $sg_getShopStatistics,
} from '@/api/other';
import Dialog from '@/components/common/dialog.vue';

// 折线图设置
const setOption = {
  tooltip: {
    crosshairs: true,
    shared: true,
  },
  colors: ['#EF3F46'],
};
export default {
  name: 'shopIndex',
  components: {
    Dialog,
  },
  data() {
    return {
      index: {
        amount: 0, // 总收益
        balance: 0, // 可提现收益
        haveMember: 0, // 已拥有商户
        unPassMember: 0, // 待提交审核商户
        curMonthPassMember: 0, // 本月新增有效商户
        expireMember: 0, // 15天内到期续费商户
      },
      moneyList: {
        mchMoneyMonthly: 0, // 本月订单金额
        mchCommissionMonthly: 0, // 本月参与抽佣金额
        mchProfitMonthly: 0, // 本月分润金额
      },
      accountBlance: 0,
      totalShop: 0,
      effectiveShopByMonth: 0,
      expireShopByDay: 0,
      searchInfo: {
        start_time: '',
        end_time: '',
      },
      activeTime: 7,
      noticeList: [],
      ProfitDetail: {},
    };
  },
  created() {
    this.getIndexData();
    this.getShopOrderDailyStatistic();
    this.getNoticeDetail();
  },
  mounted() {
    this.getMaxFund();
  },
  methods: {
    toPage(pageName) {
      this.$router.push({ name: pageName });
    },
    // 获取首页展示内容
    getIndexData() {
      $sg_queryIndexDetail().then((res) => {
        this.index = {
          ...res,
          withdrawFinance: Math.floor(res.withdrawFinance * 100) / 100,
          totalFinance: Math.floor(res.totalFinance * 100) / 100,
        };
      });
      $sg_queryShopDetail({ type: 1 }).then((res) => {
        this.totalShop = res.totalShop;
        this.effectiveShopByMonth = res.effectiveShopByMonth;
        this.expireShopByDay = res.expireShopByDay;
      });
      $sg_getShopStatistics({ wayType: 'direct' }).then((res) => {
        this.moneyList = res;
      });
    },
    // 获取获取公告详情  首页只展示3条最新的~因此取1页3条
    getNoticeDetail() {
      $sg_queryNoticeDetail({ requestPage: { pageCurrent: 1, pageSize: 3 } }).then((res) => {
        res.records.forEach((item) => {
          const elem = document.createElement('div');
          elem.innerHTML = item.content;
          const content = elem.innerText;
          item.content = content;
        });
        this.noticeList = res.records;
      });
    },
    // 获取账户最大欠费金额
    getMaxFund() {
      $sg_queryAgentMaxFund().then((res) => {
        if ((res.balance * 1) < (res.maxDebts * 1)) {
          this.accountBlance = res.balance;
          this.showTips(9);
        }
      });
    },
    // 提示弹窗
    showTips(num) {
      switch (num) {
        case 1:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-box.png',
            title: '可提现收益',
            content: '当前收益为可提现收益，为您当前有效收益，请尽量保证100以上的余额，以便出现退款订单',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 2:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-man.png',
            title: '已拥有商户',
            content: '已拥有商户为添加商户成功，并且核单成功，已帮助商户上线系统',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 3:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-man-warning.png',
            title: '待提交核单商户',
            content: '正在审核商户资料或正在为商户申请小程序或公众号或支付等，商户还未正式上线的商户',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 4:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-order.png',
            title: '本月订单金额',
            content: '本月订单金额为所有商户本月流水金额。此流水金额包含每笔订单商户的分润、配送费、支付手续费、抽佣费等所有的费用',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 5:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-box-money.png',
            title: '本月参与抽佣金额',
            content: '本月参与抽佣金额为除去商户分润后的总金额，包含代理平台总收益',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 6:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-box-money.png',
            title: '本月分润金额',
            content: '本月分润金额为，本月我的分润',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 7:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-man-success.png',
            title: '本月新增有效商户',
            content: '本月新增有效商户为本月提交签约商户，并且后台开设商户完成，已交付商户使用的有有效商户',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 8:
          this.$refs.indexDialog.dialogs = {
            icon: '/img/dialog/icon-man-question.png',
            title: '15天内到期续费的商户',
            content: '此数据为商户年服务费将在15天以内到期，需要商户尽快续费，否则商户无法继续使用',
            done: '已知晓',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        case 9:
          this.$refs.indexDialog.dialogs = {
            title: '提示',
            content: `您的账户余额已欠费${this.accountBlance}元，为了不影响正常使用，加油赚取收益哦！！！`,
            done: '知道了',
          };
          this.$refs.indexDialog.showDialog = true;
          break;
        default:
          break;
      }
    },
    // 初始化图表
    initHighCharts(categories, series) {
      const option = {
        chart: {
          type: 'spline',
        },
        title: {
          text: '',
        },
        xAxis: {
          categories,
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            formatter() {
              return this.value;
            },
          },
        },
        plotOptions: {
          series: {
            events: {
              legendItemClick() {
                return false;
              },
            },
          },
        },
        series,
        ...setOption,
        credits: {
          enabled: false,
        },
      };
      highCharts.chart('chart_content', option, () => {
        // 图表初始化完毕后的回调函数
      });
    },
    // 获取折线图数据
    getShopOrderDailyStatistic() {
      const params = { dateType: this.activeTime, wayTyep: 'sub' };
      $sg_queryProfitDetail(params).then((res) => {
        const categories = [];
        const series = [{ name: '分润金额', data: [] }];
        res.forEach((item) => {
          categories.push(item.statisticalDate);
          series[0].data.push(+item.val);
        });
        this.initHighCharts(categories, series);
      });
    },
    chooseTime(num) {
      this.activeTime = num;
      this.getShopOrderDailyStatistic();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/style/var.scss";

.container {
  width: 100%;
  height: 100%;
  min-height: 950px;
  min-width: 1650px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.head {
  width: 100%;
  overflow: hidden;
  margin: 0 0 20px 0;
  padding: 0;
  box-sizing: border-box;
}
.headModel {
  width: calc((100% - 40px) / 3);
  height: 220px;
  margin: 0;
  padding: 30px 70px 30px 60px;
  box-sizing: border-box;
  float: left;
}
.headModel:nth-child(2) {
  margin: 0 20px;
}
.bg1 {
  background: url("/img/index/bg_1_2.png") no-repeat;
  background-size: 100% 100%;
}
.bg2 {
  background: url("/img/index/bg_2_2.png") no-repeat;
  background-size: 100% 100%;
}
.bg3 {
  background: url("/img/index/bg_3_2.png") no-repeat;
  background-size: 100% 100%;
}
.headModelCon_num {
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 18px;
}
.headModelCon_title {
  margin-bottom: 14px;
  min-height: 45px;
}
.headModelCon_title_txt {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 8px;
}
.allProfit {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 2px;
}
.headModelCon_btn {
  width: 80px;
  height: 32px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 1px solid #fff;
  border-radius: 5px;
  cursor: pointer;
}
.headModelCon_btn_txt {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 6px;
}
.headIcon {
  width: 98px;
  height: 98px;
}
.middle {
  width: 100%;
  overflow: hidden;
  margin: 0 0 20px 0;
  padding: 20px 20px 60px 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px;
}
.middleContent {
  width: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.middleConModel {
  width: 20%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  float: left;
}
.middleConModel_con {
  font-size: 37px;
  font-weight: bold;
  color: $--red-color;
  margin: 10px 0 18px 0;
}
.middleConModel_title {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  margin-right: 8px;
}

.content-bottom {
  width: calc(70% - 20px);
  padding: 20px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-height: 450px;
}
.content {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 0 15px #eee;
}
.content-title {
  padding: 0;
  box-sizing: border-box;
  font-size: 18px;
}
.bottomRight {
  width: 30%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  position: relative;
}
.goDetail {
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.bottomRightContentDiv {
  width: 100%;
  height: 420px;
  overflow: auto;
  box-sizing: border-box;
}
.bottomRightContent {
  padding: 30px 15px;
  box-sizing: border-box;
}
.contentLi {
  margin-bottom: 24px;
  box-sizing: border-box;
}
.contentLi:last-child {
  margin-bottom: 0;
}
.contentLiTimeAndStatus {
  margin-bottom: 10px;
  box-sizing: border-box;
}
.contentLiStatus {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.contentLiTxt {
  padding: 15px 10px;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}
.contentLiTxtActive {
  background-color: #ffeced;
}
.contentLiTitle {
  font-size: 16px;
  font-weight: 400;
  color: #333333;
}
.contentLiTime {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}
.contentLiTxtCon {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.firstTitle {
  height: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #444444;
}
.firstTitle::before {
  content: "";
  display: block;
  width: 4px;
  height: 20px;
  margin-right: 10px;
  background-color: $--red-color;
  border-radius: 3px;
}
.border_right {
  height: 80%;
  border-right: 1px solid #cccccc;
}
.question {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.goOnDeveloping {
  width: 80px;
  height: 32px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
</style>
