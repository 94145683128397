<template>
  <div class="notice-detail-page flexbox flex-tb flex-align-center">
    <div class="title">{{noticeNowItem.title}}</div>
    <div class="date">{{formatDate(noticeNowItem.gmtCreate,'yyyy-MM')}}</div>
    <img src="@/assets/img/notice/decorate.png" />
    <div class="content" v-html="noticeNowItem.content"></div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(['noticeNowItem']),
  },
  mounted() {
    if (!Object.keys(this.noticeNowItem).length) {
      this.$router.back();
      return;
    }
    this.updateNotice({
      notice_code: this.noticeNowItem.code,
      role: 2,
    });
  },
  methods: {
    ...mapActions(['updateNotice']),
    // 日期处理
    formatDate(date, pattern) {
      return moment(date).format(pattern);
    },
  },
};
</script>
<style>
#NoticeDetail {
  background: #fff;
}
</style>
<style scoped lang="scss">
@import "@/style/var.scss";
.notice-detail-page {
  padding: 67px 88px;
  img {
    width: 266.06px;
    height: 19.15px;
    margin: 20px 0 38px;
  }
  .title {
    font-size: 30px;
    font-weight: bold;
    line-height: 42px;
    color: #666666;
  }
  .date {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #666666;
  }
  .content {
    background: rgba(250, 136, 40, 0.02);
    border: 1px solid #ececec;
    border-radius: 6px;
    padding: 30px 36px 60px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    width: 100%;
    box-sizing: border-box;
  }
}
</style>
