<template>
  <div class="notice-page">
    <div class="notice-list-page" v-if="noticeList.length">
      <div v-for="(item, index) in noticeList" :key="index" class="flexbox main-box"
        :class="item.readState==1?'read-main-box':''">
        <div class="date">
          <div class="year-month">{{formatDate(item.gmtCreate,'yyyy-MM')}}</div>
          <div class="day">{{formatDate(item.gmtCreate,'DD')}}</div>
        </div>
        <div class="right-main">
          <div class="flexbox flex-align-center">
            <div class="title">{{item.title}}</div>
            <div class="more flexbox flex-align-center">
              <div @click="showDetailDialog(item)">详情</div>
              <img :src="item.readState==1?greyMore:redMore">
            </div>
          </div>
          <!-- item.content -->
          <div class="content much-ellipsis">{{item.content|formatContent}}</div>
        </div>
      </div>
      <div class="flexbox  flex-align-center load-more">
        <div v-if="total>noticeList.length" @click="loadMore" style="cursor:pointer">加载更多</div>
        <!-- <img src="@/assets/img/common/grey-more.png"> -->
        <div v-else>没有更多内容了……</div>
      </div>
    </div>
    <EmptyData v-else :src="noneContentImg" />
  </div>
</template>
<script>
import { $sg_queryNoticeList } from '@/api/other';
import moment from 'moment';
import { mapMutations, mapActions } from 'vuex';
import greyMore from '@/assets/img/common/grey-more.png';
import redMore from '@/assets/img/common/red-more.png';
import EmptyData from '@/components/common/EmptyData.vue';
import noneContentImg from '@/assets/img/common/none-content.png';

export default {
  components: {
    EmptyData,
  },
  data() {
    return {
      greyMore,
      redMore,
      noneContentImg,
      noticeList: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
    };
  },
  created() {
    this.getNoticeList();
    this.updateNotice();
  },
  filters: {
    formatContent(text) {
      const result = text.replace(/<.*?>/ig, '');
      return result;
    },
  },
  methods: {
    ...mapMutations(['setNoticeNowItem']),
    ...mapActions(['updateNotice']),
    // 日期处理
    formatDate(date, pattern) {
      return moment(date).format(pattern);
    },
    // eslint-disable-next-line
    showDetailDialog(item) {
      this.setNoticeNowItem(item);
      this.$router.push({ name: 'NoticeDetail' });
    },
    loadMore() {
      this.currentPage += 1;
      this.getNoticeList();
    },
    getNoticeList() {
      $sg_queryNoticeList({ requestPage: { pageCurrent: this.currentPage, pageSize: this.pageSize } }).then((res) => {
        if (this.currentPage === 1) this.noticeList = [];
        this.noticeList = this.noticeList.concat(res.records || []);
        this.total = res.total;
      });
    },
  },
};
</script>
<style>
#Notice {
  padding: 0;
  background-color: #fff;
}
</style>
<style scoped lang="scss">
@import "@/style/var.scss";
.notice-list-page {
  .load-more {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #999999;
    margin: 44px 0 42px 92px;
    img {
      width: 4.73px;
      height: 9px;
      margin: 0 21px 0 10px;
    }
  }
  .read-main-box {
    .year-month,
    .title,
    .more,
    .day {
      color: #999999 !important;
    }
    .content {
      color: #cccccc !important;
    }
  }
  .main-box {
    padding-top: 30px;
    &:hover {
      background: $--background-linear-gradient-color2;
    }
    .date {
      width: 159px;
      padding-left: 92px;
      box-sizing: border-box;
      .year-month {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #14171d;
      }
      .day {
        font-size: 40px;
        font-weight: bold;
        line-height: 56px;
        color: $--theme-color;
        margin-top: 2px;
      }
    }
    .right-main {
      width: calc(100% - 292px);
      margin-left: 30px;
      margin-right: 103px;
      border-bottom: 1px solid #edf1f4;
      padding-bottom: 30px;
      .title {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: #666666;
      }
      .more {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        color: $--theme-color;
        margin-left: 20px;
        cursor: pointer;
        & > img {
          width: 4.73px;
          height: 9px;
          margin-left: 10px;
        }
      }
      .content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: #666666;
        margin-top: 20px;
      }
    }
  }
}
</style>
